module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2e23b757dab51848e0630e9641304d79"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/matthias/Development/are-web-gatsby","JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"titleTemplate":"%s | Ambia","title":"Ambia","language":"de","description":"Erlebe Maestrani's Chocolarium auf eine neue Art","languageAlternates":[{"hrefLang":"de","href":"https://ambia.app/?de"},{"hrefLang":"en","href":"https://ambia.app/?en"}],"images":[{"url":"https://ambia.app/images/hero.jpg"}],"openGraph":{"type":"website","locale":"de_DE","site_name":"Ambia","images":[{"url":"https://ambia.app/images/hero.jpg"}]},"twitter":{"handle":"@AppAmbia","site":"@AppAmbia","cardType":"summary_large_image"}},
    }]
